import { createIcon } from '@chakra-ui/react';

export const CartaoCestaBasicaIcon = createIcon({
  displayName: 'CartaoCestaBasicaIcon',
  viewBox: '0 0 40 40',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      d="M29 12.5H11C10.6022 12.5 10.2206 12.658 9.93934 12.9393C9.65804 13.2206 9.5 13.6022 9.5 14V26C9.5 26.3978 9.65804 26.7794 9.93934 27.0607C10.2206 27.342 10.6022 27.5 11 27.5H29C29.3978 27.5 29.7794 27.342 30.0607 27.0607C30.342 26.7794 30.5 26.3978 30.5 26V14C30.5 13.6022 30.342 13.2206 30.0607 12.9393C29.7794 12.658 29.3978 12.5 29 12.5ZM29 14V16.25H11V14H29ZM29 26H11V17.75H29V26ZM27.5 23.75C27.5 23.9489 27.421 24.1397 27.2803 24.2803C27.1397 24.421 26.9489 24.5 26.75 24.5H23.75C23.5511 24.5 23.3603 24.421 23.2197 24.2803C23.079 24.1397 23 23.9489 23 23.75C23 23.5511 23.079 23.3603 23.2197 23.2197C23.3603 23.079 23.5511 23 23.75 23H26.75C26.9489 23 27.1397 23.079 27.2803 23.2197C27.421 23.3603 27.5 23.5511 27.5 23.75ZM21.5 23.75C21.5 23.9489 21.421 24.1397 21.2803 24.2803C21.1397 24.421 20.9489 24.5 20.75 24.5H19.25C19.0511 24.5 18.8603 24.421 18.7197 24.2803C18.579 24.1397 18.5 23.9489 18.5 23.75C18.5 23.5511 18.579 23.3603 18.7197 23.2197C18.8603 23.079 19.0511 23 19.25 23H20.75C20.9489 23 21.1397 23.079 21.2803 23.2197C21.421 23.3603 21.5 23.5511 21.5 23.75Z"
      fill="#146CFA"
    />
  ]
});
