import { InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Icon,
  Stack,
  Text
} from '@chakra-ui/react';
import { useState } from 'react';

import { OrangePlusIcon } from '../../assets/icons/ferramentas/OrangePlusIcon';
import { PencilIconOrange } from '../../assets/icons/ferramentas/PencilIconOrange';
import { getHiddenAssistsIds } from '../../utils/general.util';
import { getNumberFormat } from '../../utils/getNumberFormat';
import { getIconByName } from '../../utils/icons.util';
import { getProductType, isCoberturaDIT } from '../../utils/products.utils';
import { SwitchValue } from '../fragments/SwitchValue';
import { ModalDetailCoverage } from '../modals/ModalDetailCoverage';

type DropdownCheckoutProps = {
  coverages: any[];
  idWebsite: string;
  idProduto: number;
  idProdutoCustomizado: string;
  isProductVariable: boolean;
  states: any;
  setStates: (states: any) => void;
  modalEditionState: any;
  setModalEditionState: (states: any) => void;
  handleModalSubmit: () => void;
  frequencyChar: string;
};

export const DropdownCheckout = ({
  coverages,
  idWebsite,
  idProduto,
  idProdutoCustomizado,
  isProductVariable,
  states,
  setStates,
  modalEditionState,
  setModalEditionState,
  handleModalSubmit,
  frequencyChar
}: DropdownCheckoutProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [content, setContent] = useState<any[]>();

  const handleOpenUpdateModal = (coverage: any) => {
    const newModalEditionState = {} as any;
    console.log('lista de doencas iniciais', coverage);
    newModalEditionState[coverage.id] = {
      valor_capital: coverage.valor_capital,
      doencas_table: {
        ...coverage.tabela_doencas,
        doencas: coverage.tabela_doencas
          ? [...coverage.tabela_doencas.doencas]
          : []
      }
    };
    console.log('valor capital', { valor_capital: coverage.valor_capital });
    setModalEditionState({ ...newModalEditionState });
    setContent(coverage);
    setIsOpenModal(true);
  };

  const hiddenIds = getHiddenAssistsIds();
  return (
    <>
      {coverages.map((coverage, index) => {
        const contratacaoObrigatoriaFlag = coverage.contratacao_obrigatoria ? (
          <Box
            w="100%"
            maxW={['291px', 'none']}
            bg="#D3DEED"
            p="16px"
            m="36px 0 0 0 !important"
            display="flex"
            alignItems="center"
            gridGap="18px"
          >
            <InfoOutlineIcon w="19px" h="19px" />
            <Text
              color="#323232"
              fontSize={['0.75rem', '1rem']}
              lineHeight={['1.25rem', '1.5rem']}
              fontWeight="400"
            >
              {coverage.type == 'cobertura'
                ? 'Cobertura obrigatória para seguir com a contratação.'
                : undefined}

              {coverage.type == 'servico'
                ? 'Assistência obrigatória para seguir com a contratação.'
                : undefined}
            </Text>
          </Box>
        ) : undefined;

        return (
          <Stack
            display={hiddenIds.includes(coverage.id) ? 'none' : undefined}
            className="cover-item"
            m="0 !important"
            bg="#FFFFFF"
            key={index}
            w="100%"
            p={['25px 19px', '35px 40px']}
            boxShadow="0px 4px 10px rgba(50, 50, 50, 0.16);"
          >
            <Accordion
              allowToggle
              m="0 !important"
              border="1px solid transparent !important"
            >
              <AccordionItem>
                <HStack justify="space-between" align="start">
                  <HStack
                    m="0 !important"
                    gap={['20px', '36px']}
                    w="100%"
                    align="center"
                  >
                    <Icon
                      as={getIconByName(coverage.icon_name)}
                      w={['33.5px', '48px']}
                      h={['40px', '50px']}
                    />
                    <HStack
                      m="0 !important"
                      flexDirection="column"
                      justifyContent="space-between"
                      align="start"
                      h="100%"
                    >
                      <Text
                        fontSize={['0.75rem', '1rem']}
                        lineHeight={['1.25rem', '1.5rem']}
                        fontWeight="700"
                      >
                        {coverage.descricao}
                        <br />
                        {coverage.valor_capital ? (
                          <>
                            Indenização de&nbsp;
                            <span style={{ color: '#FF5000' }}>
                              {getNumberFormat(coverage.valor_capital)}
                            </span>
                          </>
                        ) : (
                          ''
                        )}
                        {isProductVariable && coverage.type === 'cobertura' ? (
                          <PencilIconOrange
                            data-testid={`edit-button-${coverage.id}`}
                            ml="10px"
                            cursor="pointer"
                            onClick={() => handleOpenUpdateModal(coverage)}
                            pointerEvents={
                              states[coverage.id]?.checked ? 'all' : 'none'
                            }
                          />
                        ) : !isProductVariable && coverage.tabela_doencas ? (
                          <OrangePlusIcon
                            data-testid={`edit-button-${coverage.id}`}
                            ml="10px"
                            cursor="pointer"
                            onClick={() => handleOpenUpdateModal(coverage)}
                            pointerEvents={
                              states[coverage.id]?.checked ? 'all' : 'none'
                            }
                          />
                        ) : (
                          <></>
                        )}
                      </Text>
                      <Box display={['none', 'flex']} w="100%">
                        {contratacaoObrigatoriaFlag}
                        {isCoberturaDIT(coverage.id) ? (
                          <Box
                            w="100%"
                            maxW={['291px', 'none']}
                            bg="#D3DEED"
                            p="16px"
                            m="36px 0 0 0 !important"
                            display="flex"
                            alignItems="center"
                            gridGap="18px"
                          >
                            <InfoOutlineIcon w="19px" h="19px" />
                            <Text
                              color="#323232"
                              fontSize={['0.75rem', '1rem']}
                              lineHeight={['1.25rem', '1.5rem']}
                              fontWeight="400"
                            >
                              O valor de indenização apresentado é mensal
                            </Text>
                          </Box>
                        ) : undefined}
                        {coverage.tabela_doencas ? (
                          <Box
                            w="100%"
                            maxW={['291px', 'none']}
                            bg="#D3DEED"
                            p="16px"
                            m="36px 0 0 0 !important"
                            display="flex"
                            alignItems="center"
                            gridGap="18px"
                          >
                            <InfoOutlineIcon w="19px" h="19px" />
                            <Text
                              color="#323232"
                              fontSize={['0.75rem', '1rem']}
                              lineHeight={['1.25rem', '1.5rem']}
                              fontWeight="400"
                            >
                              Veja quais doenças graves você estará coberto
                              clicando no botão&nbsp;
                              {!isProductVariable && coverage.tabela_doencas ? (
                                <OrangePlusIcon />
                              ) : (
                                'de edição'
                              )}
                              .
                            </Text>
                          </Box>
                        ) : undefined}
                      </Box>
                    </HStack>
                  </HStack>

                  <Box display={['none', 'flex']}>
                    <SwitchValue
                      size="lg"
                      coverage={coverage}
                      states={states}
                      setStates={setStates}
                      isProductVariable={isProductVariable}
                      frequencyChar={frequencyChar}
                      onChange={
                        isCoberturaDIT(coverage.id)
                          ? () => {
                              handleOpenUpdateModal(coverage);
                            }
                          : undefined
                      }
                    />
                  </Box>

                  <AccordionButton
                    p="0 !important"
                    m="0 0 0 30px !important"
                    w="48px"
                    h="50px"
                    display="flex"
                    alignItems={['center', 'start']}
                    justifyContent="start"
                    _hover={{}}
                    _active={{}}
                    _focus={{
                      '&:focus': {
                        boxShadow: 'none !important'
                      }
                    }}
                  >
                    <AccordionIcon boxSize={8} />
                  </AccordionButton>
                </HStack>
                <Box display={['block', 'none']} w="100%">
                  {coverage.contratacao_obrigatoria ? (
                    <Box
                      w="100%"
                      maxW={['291px', 'none']}
                      bg="#D3DEED"
                      p="16px"
                      m="36px 0 0 0 !important"
                      display="flex"
                      alignItems="center"
                      gridGap="18px"
                    >
                      <InfoOutlineIcon w="19px" h="19px" />
                      <Text
                        color="#323232"
                        fontSize={['0.75rem', '1rem']}
                        lineHeight={['1.25rem', '1.5rem']}
                        fontWeight="400"
                      >
                        {contratacaoObrigatoriaFlag}

                        {coverage.type == 'servico'
                          ? 'Assistência obrigatória para seguir com a contratação.'
                          : undefined}
                      </Text>
                    </Box>
                  ) : undefined}
                </Box>
                <Box display={['flex', 'none']}>
                  <SwitchValue
                    coverage={coverage}
                    states={states}
                    setStates={setStates}
                    isProductVariable={isProductVariable}
                    size={'md'}
                    frequencyChar={frequencyChar}
                  />
                </Box>
                <AccordionPanel
                  w="100%"
                  p={['40px 32px 0 52px', '30px 84px 0']}
                >
                  <Text
                    w="100%"
                    fontSize="0.875rem"
                    fontWeight={400}
                    lineHeight="1.5rem"
                    color="#323232"
                    dangerouslySetInnerHTML={{
                      __html: coverage.html_description
                    }}
                  />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
            {isOpenModal && (
              <ModalDetailCoverage
                coverage={content}
                coverageStates={modalEditionState}
                states={states}
                setStates={setStates}
                setCoverageStates={setModalEditionState}
                idWebsite={idWebsite}
                idProdutoCustomizado={idProdutoCustomizado}
                handleClose={() => setIsOpenModal(false)}
                handleSubmit={handleModalSubmit}
                isProductVariable={isProductVariable}
              />
            )}
          </Stack>
        );
      })}
    </>
  );
};
