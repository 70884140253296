import { createIcon } from '@chakra-ui/react';

export const ComboFlexIcon = createIcon({
  displayName: 'ComboFlexIcon',
  viewBox: '0 0 40 40',
  path: [
    <rect
      key="1"
      x="0.5"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="none"
      stroke="#146CFA"
    />,
    <path
      key="2"
      d="M28.97 14.2025L20.72 9.68843C20.4996 9.56663 20.2518 9.50275 20 9.50275C19.7482 9.50275 19.5004 9.56663 19.28 9.68843L11.03 14.2044C10.7944 14.3333 10.5977 14.5231 10.4605 14.754C10.3233 14.9848 10.2506 15.2483 10.25 15.5169V24.4831C10.2506 24.7517 10.3233 25.0152 10.4605 25.246C10.5977 25.4769 10.7944 25.6667 11.03 25.7956L19.28 30.3116C19.5004 30.4334 19.7482 30.4972 20 30.4972C20.2518 30.4972 20.4996 30.4334 20.72 30.3116L28.97 25.7956C29.2056 25.6667 29.4023 25.4769 29.5395 25.246C29.6767 25.0152 29.7494 24.7517 29.75 24.4831V15.5178C29.7499 15.2488 29.6774 14.9847 29.5402 14.7533C29.403 14.5219 29.206 14.3316 28.97 14.2025ZM20 11.0009L27.5319 15.1259L24.7409 16.6541L17.2081 12.5291L20 11.0009ZM20 19.2509L12.4681 15.1259L15.6462 13.3859L23.1781 17.5109L20 19.2509ZM11.75 16.4384L19.25 20.5428V28.5856L11.75 24.4841V16.4384ZM28.25 24.4803L20.75 28.5856V20.5466L23.75 18.905V22.2509C23.75 22.4498 23.829 22.6406 23.9697 22.7813C24.1103 22.9219 24.3011 23.0009 24.5 23.0009C24.6989 23.0009 24.8897 22.9219 25.0303 22.7813C25.171 22.6406 25.25 22.4498 25.25 22.2509V18.0837L28.25 16.4384V24.4794V24.4803Z"
      fill="#146CFA"
    />
  ]
});
